/* eslint-disable no-underscore-dangle */
function isIpad() {
  const iDevices = ['iPad Simulator', 'iPad'];
  // eslint-disable-next-line no-extra-boolean-cast
  if (!!navigator.platform) {
    while (iDevices.length) {
      if (navigator.platform === iDevices.pop()) {
        return true;
      }
    }
  }

  return false;
}

function cancelMouseEventChanges(_setTimeoutObj) {
  window.clearTimeout(_setTimeoutObj);
}

(function ($) {
  let isMouseenter = false,
    currentHoverMenuTarget = '';
  const settimeoutObj = null;

  if (!isIpad()) {
    $('nav.navbar .main-nav li.has-mega-menu > a').on('mouseenter keydown', function (event) {
      if ($(window).outerWidth() >= 992) {
        if (event.type === 'mouseenter' || event.keyCode === 13) {
          const _dataTarget = $(this).attr('data-target'),
            _mainNav = $(this).closest('.main-nav-row'),
            _megaMenu = $(this).closest('.main-nav-row').find('.mega-menu-wrapper');
          _mainNav.find('.main-nav > li').removeClass('active hover').blur();

          if (currentHoverMenuTarget !== _dataTarget) {
            currentHoverMenuTarget = _dataTarget;
            cancelMouseEventChanges(settimeoutObj);
            isMouseenter = true;
            _megaMenu.addClass('d-none');
            _mainNav.find(_dataTarget).removeClass('d-none');
            _mainNav.find('.main-nav > li').removeClass('active hover').blur();
            $(this).parent().addClass('active hover');
          } else if (!_mainNav.find(_dataTarget).hasClass('d-none')) {
            if (event.keyCode === 13) {
              _mainNav.find(_dataTarget).addClass('d-none');
              currentHoverMenuTarget = '';
            }
          }
        }
      }
    });

    $('nav.navbar .main-nav li.has-mega-menu').on('mouseleave', function () {
      if ($(window).outerWidth() >= 992) {
        if (isMouseenter) {
          cancelMouseEventChanges(settimeoutObj);
          isMouseenter = false;
          currentHoverMenuTarget = '';
          $(this).dequeue();
          $(this)
            .delay(100)
            .queue(function () {
              const _dataTarget = $(this).attr('data-target'),
                _megaMenu = $(this).closest('.main-nav-row').find('.mega-menu-wrapper'),
                element = $(':hover'),
                _elem = element.eq(element.length - 1),
                _that = $(this);
              if (_elem.closest('li.has-mega-menu').length == 0) {
                if (_elem.hasClass('.mega-menu-wrapper') || _elem.closest('.mega-menu-wrapper').length > 0) {
                  $(_dataTarget).on('mouseleave', function () {
                    _megaMenu.addClass('d-none');
                    _that.removeClass('active hover').blur();
                    $(this).off('mouseleave');
                  });
                } else {
                  if (_megaMenu.length > 0) _megaMenu.addClass('d-none');
                  $(this).removeClass('active hover').blur();
                }
              }
              $(this).dequeue();
            });
        }
      }
    });
  }

  $(document).on('click', function (e) {
    if ($('.mega-menu-wrapper').length > 0) {
      if ($(window).outerWidth() >= 992) {
        e.stopPropagation();
        const container = $('.main-nav-row'),
          navContainer = $('ul.main-nav');

        // check if the clicked area is dropDown or not
        if (
          navContainer.has(e.target).length === 0 &&
          !$(e.target).hasClass('mega-menu-wrapper') &&
          container.find('.mega-menu-wrapper').has(e.target).length === 0
        ) {
          container.find('.mega-menu-wrapper').addClass('d-none');
          navContainer.find('> li').removeClass('active');
        }
      }
    }
  });

  $(window).on('resize', function () {
    if ($('.mega-menu-wrapper').length > 0) {
      if ($(window).outerWidth() < 992) {
        const container = $('.main-nav-row');
        container.find('.mega-menu-wrapper').addClass('d-none');
        container.find('.main-nav > li').removeClass('active').blur();
      }
    }
  });

  // for click event to work on document binding, iPAD needs elements to have cursor pointer property for it to trigger
  // event for the above closing of mega menu on other document / window element
  if (isIpad()) {
    $('*').css('cursor', 'pointer');
  }

  $('.has-mega-menu > a').on('keydown', (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
    }
  });
})(jQuery);
