// SASS
import '../scss/vs.scss';

// Javascript
// FSCS
//import './fscs/companies-in-default-search';

// UI
// import './ui/customer-stories-youtube';
import './ui/dropdowns';
// import './ui/tooltip';
// import './ui/date-picker';
import './ui/back-to-top';
// import './ui/page-popup-close';
import './ui/mega-menu';
// import './ui/focus-on-email-textbox';
import './ui/mobile-left-nav';
// import './ui/help-guidance';
import './ui/nav-tabs';
// import './ui/browser-compatibility';
// import './ui/xss-protect';
// import './ui/ff-search';
import './ui/add-active-class-current-page';
// import './ui/protection-checker';
// import './ui/failed-firm-list-sort';
// import './ui/fscs-custom-scroll';
// import './ui/subscript-block';
// import './ui/multi-line-clamp';
// import './ui/email-preference-center';
// import './ui/email-preference-centre-entry';
// import './ui/social-floating-icon';
// import './ui/slick-slider';
// import './ui/card-panel';
// import './ui/query-string-open-handler';
import './ui/main-nav';
// import './ui/progress-indicator-component';
// import './ui/pension-tool';

// EPiServer
//'import './episerver/forms-hacks';

// External third-party scripts
import './external/svg4everybody';

// Verification Services
//import './verification-services/base';

// // Shared blocks //
// import './shared/blocks/file-upload-drag-and-drop-block';
// import './shared/blocks/file-upload-file-list-section';
// import './shared/blocks/latest-activities-details-block';
// import './shared/blocks/numbered-progress-block';
// import './shared/blocks/vs-account-status-code-list';
// import './shared/blocks/vs-question-with-drop-down-answer-block';
// import './shared/web-chat-trigger';
// import './shared/document-download-component';
// import './shared/video-slider-component';
