/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-vars */
(($) => {
  var $listItem = $('.nav-tabs.failed-firms:not(.main-nav) li'),
    $listItemHelp = $('.nav-tabs.nav-help:not(.main-nav) li'),
    $listItemHelpDropdown = $('.nav-tabs.nav-help.dropdown-menu:not(.main-nav) li');

  $listItem.click(function () {
    var _clickedLi = $(this);

    // Loop on all li and update class
    $($listItem).each(function (i) {
      var _aHrefLoop = $(this).find('a').attr('href'),
        _aHrefClicked = _clickedLi.find('a').attr('href');
      if (_aHrefLoop === _aHrefClicked) {
        // Add class active to loop item
        $(this).addClass('active');
        // Update dropdown with new value
        $('.failed-firms-dropdown  li  a')
          .closest('.failed-firms-dropdown')
          .find('.dropdown-toggle .dropdown-btn-text')
          .text($(this).text());
        // Active tab-pane fade
        $(_aHrefLoop).addClass('active in');
      } else {
        $(this).removeClass('active');
        // Remove tab-pane fade
        $(_aHrefLoop).removeClass('active in');
      }
    });
  });

  $listItemHelp.click(function () {
    var _clickedLi = $(this);

    // Loop on all li and update class
    $($listItemHelpDropdown).each(function (i, li) {
      var _aHrefLoop = $(this).find('a').attr('href'),
        _aHrefClicked = _clickedLi.find('a').attr('href');

      if (_aHrefLoop === _aHrefClicked) {
        // Add class active to loop item
        $(li).addClass('active');
        var selectedtText = _clickedLi.find('a').text();

        // Update select text
        const currentSelection = $(this);
        $(currentSelection).closest('.fscs-dropdown').find('.dropdown-toggle .dropdown-btn-text').text(selectedtText);
      } else {
        $(li).removeClass('active');
      }
    });
  });

  $('body').on('click', '.failed-firms-dropdown  li  a', function (ev) {
    ev.preventDefault();
    const currentSelection = $(this);
    $(currentSelection)
      .closest('.failed-firms-dropdown')
      .find('.dropdown-toggle .dropdown-btn-text')
      .text($(currentSelection).text());
  });
})(jQuery);
