(function ($) {
  const nav = $('.main-nav-row');
  let navPosition;

  if (nav.length > 0) {
    navPosition = nav.offset().top;
  }

  let currentYPos = $(window).scrollTop();

  const checkNavPosition = (yPos, navPos) => {
    if (yPos > navPos) {
      nav.addClass('sticky-nav');
    } else {
      nav.removeClass('sticky-nav');
    }
  };

  checkNavPosition(currentYPos, navPosition);

  $(window).on('scroll', () => {
    currentYPos = $(window).scrollTop();

    checkNavPosition(currentYPos, navPosition);
  });
})(jQuery);
