(($) => {
  if (window.location.origin + '/' !== window.location.href) {
    // ===== Scroll back to top ====
    $(window).scroll(function () {
      // If page is scrolled more than 300px
      if ($(this).scrollTop() >= 300) {
        // Fade in the link
        $('.back-to-top').fadeIn(200);
      } else {
        // Else fade out the link
        $('.back-to-top').fadeOut(200);
      }
    });

    // ===== When arrow is clicked ====
    $('.back-to-top a').click(() => {
      // Scroll and animate back to top of body
      $('body,html').animate(
        {
          scrollTop: 0,
        },
        1000
      );
    });
  }
})(jQuery);
