/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-vars */
(($) => {
  $('body').on('click', '.fscs-dropdown .dropdown-menu > li > a:not(.btn-clear-filter)', function (ev) {
    ev.preventDefault();
    const currentSelection = $(this),
      hiddenNativeDropdown = $(currentSelection).closest('.fscs-dropdown').find('.ocs-cn-dropdown');
    $(currentSelection)
      .closest('.fscs-dropdown')
      .find('.dropdown-toggle .dropdown-btn-text')
      .text($(currentSelection).text());
    if (hiddenNativeDropdown.length > 0) {
      hiddenNativeDropdown.find('option').removeAttr('selected');
      hiddenNativeDropdown.find('option[value="' + currentSelection.attr('data-val') + '"]').prop('selected', true);
      hiddenNativeDropdown.change();
    }
  });

  $('body').on('keyup', '.fscs-dropdown .dropdown-menu .dropdown-filter', function () {
    const _filterVal = $(this).val(),
      _elDropdownUL = $(this).closest('.dropdown-menu');
    if (_filterVal === '') {
      _elDropdownUL.find('> li').show();
      $('.btn-clear-filter', _elDropdownUL).hide();
    } else {
      _elDropdownUL.find('> li:not(.filter-wrapper)').hide();
      _elDropdownUL
        .find('> li:not(.filter-wrapper)')
        .filter(':containsCI(' + _filterVal + ')')
        .show();
      $('.btn-clear-filter', _elDropdownUL).show();
    }
  });

  $('body').on('click', '.fscs-dropdown .dropdown-menu .btn-clear-filter', function (ev) {
    ev.preventDefault();
    ev.stopImmediatePropagation();
    $(this).closest('.dropdown-menu').find('.dropdown-filter').val('').trigger('keyup').focus();
  });

  $('body').on('click', '.nav-help.dropdown-menu li', function (ev) {
    ev.preventDefault();

    // Activate help tab selected
    var _aHrefClicked = $(this).find('a').attr('href'),
      listItem = $('.nav-tabs.nav-help:not(.main-nav) li');

    // Loop on all li and update class
    $(listItem).each(function (i, li) {
      var _aHrefLoop = $(li).find('a').attr('href');

      if (_aHrefLoop === _aHrefClicked) {
        var otherLiItems = $(li).parent().children();
        // Remove active class from other li
        // whcih have the same parent
        otherLiItems.each(function (i, otherLi) {
          $(otherLi).removeClass('active');
        });
        // Add class active to loop item
        $(li).addClass('active');
      }
    });
  });

  $('body').on('click', '.fscs-dropdown .dropdown-toggle', function (ev) {
    const _elDropdownWrapper = $(this).closest('.fscs-dropdown');
    $(this).blur();
    window.setTimeout(function () {
      if (_elDropdownWrapper.find('.dropdown-filter').length > 0 && _elDropdownWrapper.hasClass('open')) {
        _elDropdownWrapper.find('.dropdown-filter').focus();
      }
    }, 50);
  });
})(jQuery);

jQuery.extend(
  (jQuery.expr[':'].containsCI = function (a, i, m) {
    //-- faster than jQuery(a).text()
    var sText = a.textContent || a.innerText || '',
      zRegExp = new RegExp(m[3], 'i');
    return zRegExp.test(sText);
  })
);
