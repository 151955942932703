(($) => {
  // This new section is added to fix the URL's issue we have in Verification Services
  var overrideActive = $('li[overrideActive=true]');
  if (overrideActive.length > 0) {
    $(overrideActive).addClass('current-page');
    return false;
  }
  const currentPage = window.location.pathname.split('/')[1];
  if (currentPage !== '') {
    $('.navbar-nav.main-nav > li').each(function () {
      const eachMenuURL = $('> a', this).attr('href');
      if (typeof eachMenuURL !== 'undefined' && eachMenuURL.indexOf(currentPage) > -1) {
        $(this).addClass('current-page');
        return false;
      }
    });
  }
})(jQuery);
