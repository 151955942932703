(($) => {
  const closeMobileMenu = () => {
    if ($('.btn-menu-toggle').hasClass('menu-opened')) {
      $('.btn-menu-toggle').removeClass('menu-opened').attr('aria-expanded', 'false');
      $('#mobile-side-nav').css('width', '0px').hide();

      $('#mobile-side-nav').css({
        'overflow-x': 'hidden',
        visibility: 'hidden',
      });

      $('#overlay').hide();

      $('body').css({
        position: '',
        width: '',
      });

      $('.mobile-side-nav__sub-menu').removeClass('open');
    }
  };

  $('.btn-menu-toggle').on('click', function () {
    if (!$(this).hasClass('menu-opened')) {
      $(this).addClass('menu-opened').attr('aria-expanded', 'true');

      $('#overlay').show().css({ opacity: '0.5' });

      $('#mobile-side-nav').show().css({ width: '90%', visibility: 'visible' });

      $(this)
        .delay(750)
        .queue(function () {
          $('#mobile-side-nav').css('overflow-x', 'hidden');
          $(this).dequeue();
        });

      $('body').css({
        position: 'fixed',
        width: '100%',
      });
    }
  });

  $('.btn-close').on('click', () => {
    closeMobileMenu();
  });

  $('#mobile-side-nav li.has-mega-menu > a').on('click', function (e) {
    e.preventDefault();

    const linkSubMenu = $(this).next();

    linkSubMenu.hasClass('open') ? null : linkSubMenu.addClass('open');
  });

  $('#mobile-side-nav .mobile-side-nav__sub-menu-back-btn').on('click', function () {
    const currentSubmenu = $(this).parent();

    currentSubmenu.removeClass('open');
  });

  const dropdownUl = $('.navbar-mobile i').next();
  if ($(window).outerWidth() < 992) {
    const liItems = dropdownUl.children('li');
    if (liItems.length == 0) {
      $('.navbar-mobile').removeClass('d-block').hide();
      $('.navbar-mobile').removeClass('d-sm-block').hide();
    } else if (liItems.length == 1) {
      $('.navbar-mobile i').hide();
    }
  }

  $('.navbar-mobile i, .navbar-mobile .submenu-dropdown').on('click', () => {
    const dropdownArrow = $('.navbar-mobile i');

    if (dropdownArrow.hasClass('arrow-menu-down')) {
      dropdownArrow.addClass('arrow-menu-up');
      dropdownArrow.removeClass('arrow-menu-down');

      if (dropdownUl.hasClass('submenu-dropdown') && dropdownUl.has('li').length) {
        dropdownUl.children().show();
      }
    } else {
      dropdownArrow.addClass('arrow-menu-down');
      dropdownArrow.removeClass('arrow-menu-up');

      if (dropdownUl.hasClass('submenu-dropdown') && dropdownUl.has('li').length) {
        dropdownUl.children().not(':first-child').hide();
      }
    }
  });

  $(window).on('orientationchange resize', () => {
    if ($('#mobile-side-nav').length) {
      $('#overlay').hide();

      const width = $('#mobile-side-nav').width();
      if (width != 0) {
        closeMobileMenu();
      }
    }
  });

  $('#overlay').on('click', () => {
    closeMobileMenu();
  });
})(jQuery);
